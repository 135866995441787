/* eslint-disable eqeqeq */
import { constants as coreConstants, utils } from '@wsb/guac-widget-core';

const { imageDimensionConfig, FIT_IMAGE, LANDSCAPE, CIRCLE } = coreConstants.imageDimensions;
const transparentPlaceholder = '//img1.wsimg.com/isteam/ip/static/transparent_placeholder.png';

export const LANDSCAPE_ASPECT_RATIO = imageDimensionConfig[LANDSCAPE].aspectRatio;
export const CIRCLE_IMAGE_DIMENSION = CIRCLE;

export const CDN_REGEX = /[.-]wsimg\.com\//;
const { hasOwnProperty } = Object.prototype;

export function isInternalUrl(url) {
  return !!CDN_REGEX.test(url);
}

function parseOperationsUrl(operationsUrl) {
  if (!operationsUrl) {
    return [];
  }
  return operationsUrl
    .split('/')
    .filter(Boolean)
    .map((operation) => {
      const [name, value] = operation.split('=');
      return { name, value };
    });
}

function parseImageApiUrl(url) {
  if (!isInternalUrl(url)) {
    return null;
  }
  const [source, operations] = url.split('/:/');
  return { source, operations: parseOperationsUrl(operations) };
}

function stringifyParsedImageApiUrl({ source, operations }) {
  if (operations == null) {
    return source;
  }
  const operationsUrl = operations
    .map(({ name, value }) => value == null ? name : `${name}=${value}`)
    .join('/');
  if (!operationsUrl) {
    return source;
  }
  return source + '/:/' + operationsUrl;
}

function toOperationsArray(operations) {
  if (operations == null) {
    return [];
  }
  if (typeof operations === 'string') {
    return parseOperationsUrl(operations);
  }
  if (Array.isArray(operations)) {
    return operations;
  }
  return Object.entries(operations).map(([name, value]) => ({ name, value }));
}

export function addImageApiArgs(url, operations) {
  const parsedUrl = parseImageApiUrl(url);
  if (parsedUrl == null) {
    return url;
  }
  return stringifyParsedImageApiUrl({
    source: parsedUrl.source,
    operations: [
      ...parsedUrl.operations,
      ...toOperationsArray(operations)
    ]
  });
}

export function replaceImageApiArgs(url, operations) {
  const parsedUrl = parseImageApiUrl(url);
  if (parsedUrl == null) {
    return url;
  }
  // if the operation doesn't already exists, it's ignored
  parsedUrl.operations.forEach(operation => {
    const { name } = operation;
    if (hasOwnProperty.call(operations, name)) {
      // Replacing value by mutating the object
      operation.value = operations[name];
    }
  });
  return stringifyParsedImageApiUrl(parsedUrl);
}

// Please avoid this method if you can and use replaceImageApiArgs instead.
export function replaceImageSizeParams(url, replacement = '') {
  if (!url || typeof url !== 'string') {
    return '';
  }
  return url.replace(/\/:\/rs=w:[0-9]*,h:[0-9]*/, replacement);
}

export function stripParams(url = '') {
  return url.split('/:/')[0];
}

export function getValidUrl(url) {
  if (!isInternalUrl(url)) {
    return stripParams(url);
  }
  return url;
}

export function generateBackgroundUrl(background, includeDynamicResize = true) {
  const url = includeDynamicResize
    ? utils.generateBackgroundUrl(background)
    : utils.generateBackgroundUrl(background)
      .replace(/\/rs=w:{width},h:{height},cg:true,m\/cr=w:{width},h:{height},a[x]?:[^/]*/, '')
      .replace(/\/:$/, '');

  return getValidUrl(url);
}

export function resolveImageSource(imageData, legacySrc, fallback) {
  const url = imageData?.imageUrl || imageData?.image;
  if (typeof url === 'string') {
    return utils.generateImageServiceUrl(imageData);
  }

  if (fallback?.fallbackBackgroundImageSrc) {
    return fallback.fallbackBackgroundImageSrc.replace(/\{(width|height)\}/g, '+0');
  }

  return legacySrc || '';
}

/* eslint-disable max-len */
export const lazyLoadImagesScriptMinified = `const imageObserver=new IntersectionObserver((t,e)=>{t.forEach(t=>{if(t.isIntersecting){const r=t.target,a=r.getAttribute("data-srclazy"),s=r.getAttribute("data-srcsetlazy");a&&(r.src=a),s||r.removeAttribute("sizes"),r.srcset=r.getAttribute("data-srcsetlazy")||"",r.removeAttribute("data-lazyimg"),r.removeAttribute("data-srclazy"),r.removeAttribute("data-srcsetlazy"),e.unobserve(r)}})},{rootMargin:"150px"});document.addEventListener("DOMContentLoaded",function(){document.querySelectorAll("[data-lazyimg]").forEach(t=>imageObserver.observe(t))});`;
/* lazy load images script:
const imageObserver = new IntersectionObserver((entries, imgObserver) => {
  entries.forEach(entry => {
    if (entry.isIntersecting) {
      const lazyImage = entry.target;
      const src = lazyImage.getAttribute('data-srclazy');
      const srcSet = lazyImage.getAttribute('data-srcsetlazy');
      if (src) {
        lazyImage.src = src;
      }
      if (!srcSet) {
        lazyImage.removeAttribute('sizes');
      }
      lazyImage.srcset = lazyImage.getAttribute('data-srcsetlazy') || '';
      lazyImage.removeAttribute('data-lazyimg');
      lazyImage.removeAttribute('data-srclazy');
      lazyImage.removeAttribute('data-srcsetlazy');
      imgObserver.unobserve(lazyImage);
    }
  })
}, { rootMargin: '150px' });
document.addEventListener('DOMContentLoaded', function() {
  document.querySelectorAll('[data-lazyimg]').forEach(node => imageObserver.observe(node));
});
*/

export function getImageDimensionData(imageData = {}) {
  const { outputWidth, outputHeight, imageDimension, enableImageDimension } = imageData;
  const imageDimensionStyles = {};
  const parsedImageData = { ...imageData };

  if (imageDimension) {
    if (imageDimension === FIT_IMAGE) {
      delete parsedImageData.outputHeight;
      delete parsedImageData.editedAspectRatio;
      imageDimensionStyles.borderRadius = 0;
    } else {
      const { aspectRatio, borderRadius } = imageDimensionConfig[imageDimension] || {};
      if (aspectRatio && outputWidth) {
        parsedImageData.outputHeight = outputWidth / aspectRatio;
      } else if (aspectRatio && outputHeight) {
        parsedImageData.outputWidth = outputHeight * aspectRatio;
      }
      imageDimensionStyles.borderRadius = borderRadius;
    }
  }

  return {
    imageDimensionStyles,
    parsedImageData,
    enableImageDimension
  };
}

// oHeight and oWidth values need to be shifted if an image is rotated
// rotation would affect aspect ratio based calculations
export function getOriginalImageDimensions({ oWidth, oHeight, rotation }) {
  const parsedRotation = parseInt(rotation || 0, 10);
  const hasShiftedDimensions = parsedRotation % 180 > 0;
  return {
    height: hasShiftedDimensions ? oWidth : oHeight,
    width: hasShiftedDimensions ? oHeight : oWidth
  };
}

export function getInferredDimensions(imageData = {}) {
  const { outputHeight, outputWidth, editedAspectRatio } = imageData;
  const { height, width } = getOriginalImageDimensions(imageData);
  const aspectRatio = editedAspectRatio || (outputWidth && outputHeight && outputWidth / outputHeight) || (width / height);
  if (isNaN(aspectRatio) || !isFinite(aspectRatio)) return {};
  const inferredHeight = outputHeight || outputWidth / aspectRatio;
  const inferredWidth = outputWidth || outputHeight * aspectRatio;
  return {
    outputHeight: inferredHeight && inferredWidth ? inferredHeight : height,
    outputWidth: inferredHeight && inferredWidth ? inferredWidth : width,
    aspectRatio
  };
}

function generateClampedSource(inferredImageDimensions, maxSize) {
  const { outputHeight, outputWidth, aspectRatio } = inferredImageDimensions;
  let scaledWidth = Math.max(0, Math.min(outputWidth, maxSize));
  const widthRatio = outputWidth / scaledWidth;
  let scaledHeight = Math.floor(outputHeight / widthRatio);
  if (!outputWidth && aspectRatio) {
    scaledWidth = maxSize;
    scaledHeight = scaledWidth / aspectRatio;
  }
  let args;
  if (scaledWidth) {
    args = `w:${scaledWidth}`;
    if (scaledHeight) {
      args += `,h:${scaledHeight}`;
    }
    args += ',cg:true,m,i:true';
  }
  return addImageApiArgs(transparentPlaceholder, { rs: args, qt: 'q:1' });
}

export function createClampedSources(inferredImageDimensions, sizes) {
  return sizes.map(size => {
    return `${generateClampedSource(inferredImageDimensions, size)} ${size}w`;
  });
}
